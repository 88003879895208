import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import tagIcon from "./asset/Icons/tagIcon.svg";
import dateIcon from "./asset/Icons/dateIcon.svg";
import { Container } from "react-bootstrap";
import { viewEvent } from "../Service/Services";
import { useParams } from "react-router-dom";

const EventView = () => {
  const [data, setData] = useState(null);
  const { eventId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await viewEvent(eventId);
        console.log(response);
        setData(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [eventId]);

  const [mainImage, setMainImage] = useState(data?.event_images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % data?.event_images.length;
      setCurrentIndex(nextIndex);
      setMainImage(data?.event_images[nextIndex]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex, data]);

  const handleImageClick = (image, index) => {
    setMainImage(image);
    setCurrentIndex(index);
  };

  return (
    <Container fluid>
      <Title title="Event view | Palynx" />

      <Header />
      <Container className="event-cont">
        <Container className="event-hero">
          <div className="event-imgCont">
            <img
              style={{ borderRadius: "24px" }}
              width="100%"
              height="345px"
              className="event-imgCont-main"
              src={
                mainImage
                  ? mainImage.event_image_url
                  : data?.event_images[0].event_image_url
              }
              alt=""
            />

            <div className="event-subImg">
              {data?.event_images.slice(0).map((image, index) => (
                <div style={{ marginRight: "3%" }} key={index}>
                  <img
                    width="57px"
                    height="57px"
                    src={image.event_image_url}
                    alt=""
                    onClick={() => handleImageClick(image, index)}
                    style={{
                      border:
                        mainImage?.event_image_url === image.event_image_url
                          ? "2px solid red"
                          : "none",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="event-decs-cont">
            <h1 className="event-desc-header">{data?.event_name}</h1>
            <p className="event-desc-detail-header">Details</p>
            <p>{data?.details}</p>
            <div className="event-tags-cont">
              {/* {data?.hash_tags?.map((tag, index) => {
                return (
                  <div key={index} className="event-tag">
                    <p style={{ marginBottom: "0" }}>{tag}</p>
                  </div>
                );
              })} */}
              <div className="event-tag">
                <p style={{ marginBottom: "0" }}>#{data?.hash_tags}</p>
              </div>
            </div>
            <div className="event-fee-cont">
              <div className="event-fee">
                <h5 style={{ fontSize: "14px" }}>Fee</h5>
                <h1 style={{ fontSize: "28px" }}>${data?.price}</h1>
              </div>

              <div className="event-download-app-cont">
                <button
                  style={{ fontWeight: "600" }}
                  className="event-download-app-cont-btn"
                >
                  Download the app to book a spot
                </button>
              </div>
            </div>
          </div>
        </Container>

        <Container className="event-add-info-cont">
          <div className="event-add-info">
            <h3 style={{ fontWeigt: "700", fontSize: "20px" }}>
              Requirements and Additional Information
            </h3>
            <div>
              <h4
                style={{
                  fontSize: "14px",
                  fontWeight: "600px",
                  color: "#67655E",
                  marginTop: "5%",
                }}
              >
                Gear we provide:
              </h4>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={tagIcon} alt="tagIcon" />
                <h5 style={{ fontSize: "16px", marginLeft: "5px" }}>
                  Group First Aid Kit
                </h5>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={tagIcon} alt="tagIcon" />
                <h5 style={{ fontSize: "16px", marginLeft: "5px" }}>
                  Headlamp/Flashlight
                </h5>
              </div>
            </div>

            <div>
              <h4
                style={{
                  fontSize: "14px",
                  fontWeight: "600px",
                  color: "#67655E",
                  marginTop: "5%",
                }}
              >
                Participants:
              </h4>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={dateIcon} alt="dateIcon" />
                <h5 style={{ fontSize: "16px", marginLeft: "5px" }}>
                  Age 15 and up
                </h5>
              </div>
            </div>
          </div>

          <div className="event-map-info">
            <LoadScript googleMapsApiKey="AIzaSyBWuFfEpiUUS9x7Q8hawwfYKjegXRElztY">
              <GoogleMap
                mapContainerStyle={{
                  height: "145px",
                  width: "100%",
                  borderRadius: "24px 24px 0 0",
                }}
                center={{ lat: data?.latitude, lng: data?.longitude }}
                zoom={15}
              >
                <Marker
                  position={{ lat: data?.latitude, lng: data?.longitude }}
                />
              </GoogleMap>
            </LoadScript>

            <div className="event-map-desc">
              <h3>Catskill, NY</h3>
              <p>{data?.address}</p>
            </div>
          </div>
        </Container>
      </Container>
      <Footer />
    </Container>
  );
};

export default EventView;
