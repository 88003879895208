import axios from 'axios';
import { baseURL } from '../config';

const asyncHandler = (fn) => {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        console.error(error);
        throw error; 
      }
    };
  };

  export const viewEvent = asyncHandler(
    async (data) => {
      const response = await axios.get(`${baseURL}/general/events/${data}`);
      return response;
    }
  );