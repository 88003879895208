import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

import "@fortawesome/fontawesome-free/css/all.min.css";
import Title from "./Title";
const Contact = () => {
  return (
    <>
      <Title title="Contact us | Palynx" />
      <Header />

      <Container>
        <Container>
          <Row className="contact">
            <Col xs={12} md={5} className="contact-details">
              <Row>
                <Col>
                  <h4>
                    <b className="Touch">Get in touch with us.</b>{" "}
                  </h4>
                  <p>Reach out for any inquiry.</p>
                  <pre className="mt-5">Send us an email on:</pre>
                  <div>
                    <p className="contactSupport">
                      <i class="bi bi-envelope mx-2"></i>
                      <b>support@palynx.com</b>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="Contact-form" xs={12} md={7}>
              <Form className="contact-form">
                <Form.Group className="mb-2" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    className="lastName bg-transparent outline-0"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    className="lastName bg-transparent"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Phone number"
                    className="lastName bg-transparent"
                  />
                </Form.Group>

                <Form.Group controlId="floatingTextarea2" label="Comments">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "200px" }}
                    className="bg-transparent"
                  />
                </Form.Group>
                <Link to="#">
                  <button className="contact-btn">Submit</button>
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};
export default Contact;
