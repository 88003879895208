import React from "react";
import "./downloadApp.css";
import android from "../images/android.svg";
import apple from "../images/apple.svg";
import qrImg from "../images/palynxQrCode.png";
import cancelImg from "../images/cancel.svg";

import { useModal } from "./DownloadModalContext";

const DownloadAppModal = () => {
  const { isModalOpen, closeModal } = useModal();

  if (!isModalOpen) return null;

  return (
    <div className="app__modal__cont">
      <div className="overlay__modal" onClick={closeModal}></div>
      <div className="download__card__cont">
        <button className="close__modal__but" onClick={closeModal}>
          <img src={cancelImg} alt="close" />
        </button>
        <div className="download__card__top_section">
          <div className="download_logo_cont">
            <img src={apple} alt="ios" className="download__logo__apple" />
            <img src={android} alt="ios" className="download__logo__android" />
          </div>
          <h2 class=" scan__text">Scan to download</h2>
          <p class="text-text-soft-200 download__mid__text">
            Position your phone camera within the frame and follow the link to
            download.
          </p>
          <div className="qr__cont">
            <img src={qrImg} alt="qr code" className="qr__img" />
          </div>
        </div>
        <div className="download__card__footer">
          <p>
            Download the Palynx app and Sign up or log in with your account.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppModal;
