import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container, Navbar, Nav } from "react-bootstrap";
import { BiLogoLinkedin } from "react-icons/bi";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, NavLink } from "react-router-dom";
import NavbarBtn from "./NavbarBtn";
import logo from "./asset/Icons/logo.svg"

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" className="Navbar custom-sticky">
      <Container className="nbar">
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" className="logo-img" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border border-0 Nav-Toggle"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          <i className={`bi ${expanded ? "bi-x" : "bi-filter-right"}`}></i>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="menu-event">
          <Nav className="me-auto tem">
            <Nav.Link as={NavLink} to="/" className="">
              Home
              <i class="bi bi-chevron-right footer-Angle"></i>
            </Nav.Link>

            <Nav.Link as={NavLink} to="/blog" className="">
              Blog
              <i class="bi bi-chevron-right footer-Angle"></i>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/faqs" className="">
              Faqs
              <i class="bi bi-chevron-right footer-Angle"></i>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contact-us" className="">
              Contact
              <i class="bi bi-chevron-right footer-Angle"></i>
            </Nav.Link>
            <Nav.Link className="footer-Angle2">
              Contact
              <i class="bi bi-chevron-right footer-Angle2"></i>
            </Nav.Link>
          </Nav>
          <NavbarBtn />

          <div className="Navbar-icon-Cont">
            <h6>
              We are <span>Social</span>
            </h6>
            <div className="d-flex  Navbar-iconMobile">
              <Link
                to="https://www.linkedin.com/company/palynxapp/"
                className="Linked-btn2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-icon7">
                  <BiLogoLinkedin />
                </button>
              </Link>
              <Link
                to="https://www.instagram.com/palynxapp?igsh=cTd0Zmp5eG42NGgw"
                className="Linked-btn2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-icon7">
                  <BsInstagram />
                </button>
              </Link>
              <Link
                to="https://www.facebook.com/palynxapp?mibextid=JRoKGi"
                className="Linked-btn2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-icon7">
                  <FaFacebookF />
                </button>
              </Link>
              <Link
                to="https://x.com/palynxapp"
                className="Linked-btn2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-icon7">
                  <FaXTwitter />
                </button>
              </Link>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Header;
