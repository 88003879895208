import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";

const DownloadModal = createContext();

export const useModal = () => useContext(DownloadModal);

export const DownloadModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userAgent = navigator.userAgent;

  const openModal = useCallback(() => {
    if (/mobile/i.test(userAgent) || /tablet/i.test(userAgent)) {
      window.location.href = " https://app.palynx.com/download_app";
    } else {
      setIsModalOpen(true);
    }
  }, [userAgent]);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isModalOpen]);

  return (
    <DownloadModal.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </DownloadModal.Provider>
  );
};
