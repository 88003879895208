import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home.jsx";
import Blog from "./components/Blog.jsx";
import Blogcategory from "./components/Blogcategory.jsx";
import Faqs from "./components/Faqs.jsx";
import Privacy from "./components/Privacy.jsx";
import Terms from "./components/Terms.jsx";
import Contact from "./components/Contact.jsx";
import Error from "./components/Error.jsx";
import EventView from "./components/EventView.jsx";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-category" element={<Blogcategory />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="*" element={<Error />} />
          <Route path="/event/:eventId" element={<EventView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
