import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container} from "react-bootstrap";
import { BiLogoLinkedin } from "react-icons/bi";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link} from "react-router-dom";
import logo from "./asset/Icons/logo.svg"


const Footer = () => {
  return (
    <Container fluid>
      <Container fluid className="footer">
        <Container>
          <div class="row">
            <div class=" col-sm-3 footerP">
              <img
                src={logo}
                alt="logo"
                className="logo-img b-ff"
              />
              <p className="">Discover, Connect, Plan</p>
              <div className="d-flex footerp-iconMobile">
                <Link
                  to="https://www.linkedin.com/company/palynxapp/"
                  className="Linked-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-icon2">
                    <BiLogoLinkedin />
                  </button>
                </Link>

                <Link
                  to="https://www.instagram.com/palynxapp?igsh=cTd0Zmp5eG42NGgw"
                  className="Linked-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-icon2">
                    <BsInstagram />
                  </button>
                </Link>

                <Link
                  to="https://www.facebook.com/palynxapp?mibextid=JRoKGi"
                  className="Linked-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-icon2">
                    <FaFacebookF />
                  </button>
                </Link>

                <Link
                  to="https://x.com/palynxapp"
                  className="Linked-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-icon2">
                    <FaXTwitter />
                  </button>
                </Link>
              </div>
            </div>
            <div class="col-sm-3 footerp">
              {/* <p>
                <Link to="/blog" className="footer-links  d-none">
                  <p>
                    Blog
                    <i class="bi bi-chevron-right footer-Angle"></i>
                  </p>
                </Link>
              </p> */}
              <p>
                <Link to="/faqs" className="footer-links">
                  <p>
                    Faqs
                    <i class="bi bi-chevron-right footer-Angle"></i>
                  </p>
                </Link>
              </p>
              <p>
                <Link to="/contact-us" className="footer-links">
                  <p>
                    Contact
                    <i class="bi bi-chevron-right footer-Angle"></i>
                  </p>
                </Link>
              </p>
            </div>
            <div class="col-sm-3 footerp">
              <p>
                <Link to="/terms" className="footer-links">
                  <p>
                    Terms of Service
                    <i class="bi bi-chevron-right footer-Angle"></i>
                  </p>
                </Link>
              </p>
              <p>
                <Link to="/privacy-policy" className="footer-links">
                  <p>
                    Privacy & Policy
                    <i class="bi bi-chevron-right footer-Angle"></i>
                  </p>
                </Link>
              </p>
            </div>
            <div class="col-sm-3 footerp">
              <p className="Down-app">Download App</p>
              <Link
                to="https://apps.apple.com/us/app/palynx/id6480189332"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-btn"
              >
                <button className="download-button">
                  <i className="bi bi-apple"></i>
                  <div className="text-container ">
                    Download on the <br /> <span>App Store</span>
                  </div>
                </button>
              </Link>

              <div className="PalynxT"></div>
            </div>
          </div>
          <div className="d-flex justify-content-center foter">
            <h6>All rights reserved Palynx © 2024</h6>
          </div>
        </Container>
      </Container>
    </Container>
  );
}
export default Footer